import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import * as styles from "./Header/Header.module.css"

import HeaderNavItem from "./Header/HeaderNavItem"
import HorizenLogo from "../assets/HorizenLogo"
import MobileNavigation from "./Header/MobileNavigation"
import NavContainer from "../templates/NavContainer"
import SubMenuItem from "./Header/SubMenuItem"
import useDocumentScrollThrottled from "../utils/useDocumentScrollThrottled"
import SocialNetworks from "./Header/SocialNetworks"
import { URLProvider } from "../utils/URLProvider"

const Header = () => {
    const { locale } = useIntl()

    const [shouldHideHeader, setShouldHideHeader] = useState(false)

    const MINIMUM_SCROLL = 80
    const TIMEOUT_DELAY = 500

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useDocumentScrollThrottled((callbackData: any) => {
        const { previousScrollTop, currentScrollTop } = callbackData
        const isScrolledDown = previousScrollTop < currentScrollTop
        const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL

        setTimeout(() => {
            setShouldHideHeader(isScrolledDown && isMinimumScrolled)
        }, TIMEOUT_DELAY)
    })

    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)
    useEffect(() => {
        if (shouldHideHeader) {
            setIsMobileMenuOpened(false)
        }
    }, [shouldHideHeader])

    const hiddenStyle = shouldHideHeader ? `${styles.hidden} ` : ""

    return (
        <header
            className={`${styles.header} ${hiddenStyle} bg-transparent text-white z-10 top-0 w-full fixed`}
        >
            <NavContainer>
                <div className="hidden justify-between lg:flex relative">
                    <div className="space-x-10 flex">
                        <HeaderNavItem
                            label="Whitepaper"
                            href={
                                URLProvider.URL_HORIZEN_2_0_WHITEPAPER_DOWNLOAD
                            }
                        />
                        <HeaderNavItem
                            label="Docs"
                            href={URLProvider.URL_HORIZEN_DOCS}
                        />
                        <HeaderNavItem label="Ecosystem">
                            <div className="grid gap-[1px] border border-t-0 bg-white">
                                <SubMenuItem
                                    title="EON Ecosystem"
                                    link={URLProvider.URL_HORIZEN_EON}
                                    openNewTab
                                />
                                <SubMenuItem
                                    title="ZEN"
                                    link={URLProvider.LINK_ZEN}
                                />
                                <SubMenuItem
                                    title="Governance"
                                    link={URLProvider.URL_HORIZEN_GOVERNANCE}
                                />
                            </div>
                        </HeaderNavItem>
                    </div>
                    <SocialNetworks />
                </div>
                <div>
                    <div className="lg:flex items-center lg:mt-[-3rem] border-b lg:border-b-0">
                        <div className="flex-grow border-b mr-3 hidden lg:block" />
                        <div className="z-10 flex justify-between items-center">
                            <Link to={`/${locale}`}>
                                <HorizenLogo />
                            </Link>
                            <MobileNavigation
                                isMobileMenuOpened={isMobileMenuOpened}
                                setIsMobileMenuOpened={setIsMobileMenuOpened}
                            />
                        </div>
                        <div className="flex-grow border-b ml-3 hidden lg:block" />
                    </div>
                </div>
            </NavContainer>
        </header>
    )
}

export default Header
